import { BurgerBtnStyled } from './BurgerBtn.styled';


export const BurgerBtn = ({ isOpen, toggle }) => {
  
  const handleClick = event => {
    toggle();
    event.stopPropagation();
  }
  
  return (
    <BurgerBtnStyled isOpen={isOpen} onClick={handleClick}>
      <div />
      <div />
      <div />
    </BurgerBtnStyled>
  );
};

