import styled, { css } from 'styled-components';

const Card = styled.div`
  width: 350px;
  max-width: 100%;
  background: ${props => props.white ? 'white' : 'var(--snow)' };
  box-shadow: 0 5px 50px rgba(138, 138, 138, 0.04);
  border-radius: 12px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const textareaMixin = css`
  height: 80px;
  resize: none;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  margin-top: 5px;
  border: 1px solid var(--blackpearl);
  border-radius: 8px;
  font-family: var(--family);
  font-size: 16px;
  line-height: 150%;
  outline: none;
  opacity: .6;

  ${props => props.as === 'textarea' && textareaMixin}
  
  :focus {
    opacity: 1;
  }
`;

const ErrorField = styled.div`
  height: 20px;
`;

const ErrorMessage = styled.span`
  height: 20px;
  font-size: 14px;
  color: #bf1650;

  ::before {
    display: inline;
    content: "⚠ ";
  }
`;

export const styledFeedback = { Card, Form, Input, ErrorMessage, ErrorField };