import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`;

const ServicesWrapper = styled.div`
  display: grid;
  grid: auto-flow / repeat(4, 1fr);
  gap: 20px;
`;

const Brands = styled.div`
  display: grid;
  grid: auto-flow / repeat(2, minmax(min-content, 90px));
  place-items: center;
  place-content: center;
  gap: 0 80px;
  
  @media (min-width: 640px) {
    grid: auto-flow / repeat(10, minmax(min-content, 90px));
    place-content: space-between;
    gap: 20px;
  }
`;

export const servicesStyled = { Wrapper, ServicesWrapper, Brands };