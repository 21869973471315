import { Fragment } from 'react';
import { vacancyStyled } from './Vacancy.styled';


export const Vacancy = ({ data }) => {
  
  const { Wrapper, Name, Detail, Description } = vacancyStyled;
  const { name, ...fields } = data;
  
  return (
    <Wrapper>
      <Name>
        <p className='h3-size'>{name}</p>
      </Name>
      <Description>
        {
          Object.keys(fields)
            .map(field =>
              <Fragment key={field}>
                <strong>{field}:</strong><span>{data[field]}</span>
              </Fragment>)
        }
      </Description>
      <Detail href="#contacts">Ask for details ></Detail>
    </Wrapper>
  );
};

