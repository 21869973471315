import styled from 'styled-components';
import { textGradient } from '../../mixins';

const Wrapper = styled.div`
  width: 95%;
  max-width: 335px;
  padding: 30px;
  background: white;
  border-radius: 24px;
  margin: 0 auto;
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
`;

const Name = styled.div`
  min-height: 72px;
  height: 30%;
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  display: grid;
  grid: auto-flow / auto auto;
  gap: 0 27px;
  white-space: nowrap;
`;

const Detail = styled.a`
  font-size: 24px;
  ${textGradient};
  
  :hover {
    font-weight: var(--fw-bold);
  }
`;

export const vacancyStyled = { Wrapper, Name, Detail, Description };