import styled from 'styled-components';
import { ReactComponent as Facebook } from '../img/facebook.svg';
import { ReactComponent as LinkedIn } from '../img/linkedin.svg';
import { ReactComponent as Twitter } from '../img/twitter.svg';
import { ReactComponent as Instagram } from '../img/instagram.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;

  svg:hover {
    height: 45px;
    width: 45px;
  }
`;

export const SocialButtons = ({links}) => {
  return (
    <Wrapper>
      {links.facebook && <StyledLink href={links.facebook}><Facebook /></StyledLink>}
      {links.linkedin && <StyledLink href={links.linkedin}><LinkedIn /></StyledLink>}
      {links.twitter && <StyledLink href={links.twitter}><Twitter /></StyledLink>}
      {links.instagram && <StyledLink href={links.instagram}><Instagram /></StyledLink>}
    </Wrapper>
  );
};

