import { TopSection, Features, Services, Vacancies, Trust, Stages, Reviews, Team, Footer, Copyright } from './components';
import { GlobalStyles } from './GlobalStyles';

function App() {
  
  return (
    <>
      <GlobalStyles />
      <TopSection />
      <Features />
      <Services />
      <Vacancies />
      <Trust />
      <Stages />
      <Reviews />
      <Team />
      <Footer />
      <Copyright />
    </>
  );
}

export default App;
