import styled from 'styled-components';
import icon from '../../img/trust-check.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
`;

const TrustItem = styled.span`
  font-weight: var(--fw-bold);
  padding-left: 42px;
  position: relative;

  ::before {
    content: url(${icon});
    position: absolute;
    top: -7px;
    left: 0;
  }
`;

export const trustStyled = { Wrapper, TextWrapper, TrustItem };