import { useEffect, useState } from 'react';

export const useContentData = fileName => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`data/${fileName}.json`);
      setData(await response.json());
    }
    getData().then();
  }, []);
  
  return data
}

