import styled from 'styled-components';

const HeaderStyled = styled.header`
  padding: 13px 20px;
  border-radius: 0 0 20px 20px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (min-width: 640px) {
    padding: 15px 30px;
  }
`;

const Logo = styled.img`
  height: 35px;
  position: relative;
  z-index: 10;

  @media (min-width: 640px) {
    height: 46px;
  }
`;

const MenuWrapper = styled.nav`
  display: flex;
  gap: 30px;
`;

export const styledHeader = { StyledHeader: HeaderStyled, Logo, MenuWrapper };