import styled from 'styled-components';
import icon from '../../img/feature-check.svg';

const StyledSection = styled.section`
  background: var(--snow);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--gap);

  @media (min-width: 640px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const Image = styled.img`
  max-width: 100%;

  @media (min-width: 640px) {
    max-width: 50%;
  }
`;

const TextWrapper = styled.div`
  width: 540px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`;

const Feature = styled.div`
  position: relative;
  padding-left: 70px;
  
  ::before {
    content: url(${icon});
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const featuresStyled = { Wrapper, TextWrapper, Feature, Image, StyledSection };