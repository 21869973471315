import styled from 'styled-components';

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
`;

const Reviewer = styled.div`
  width: 80%;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: flex-start;
  flex-wrap: wrap;
  column-gap: 20px;
`;

const Photo = styled.img`
  height: 55px;
  border-radius: 50%;
  //overflow: hidden;
  //border: 5px solid transparent;
  //background-image: var(--gradient);
`;

const Name = styled.span`
  font-weight: var(--fw-bold);
`;

export const styledReviewer = { Wrapper, Reviewer, Photo, Name };