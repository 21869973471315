import styled from 'styled-components';

const StyledSection = styled.section`
  background: var(--snow);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--gap);
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 640px) {
    flex-direction: row;
    gap: var(--gap);
  }
`;

const Slogan = styled.p`
  font-weight: var(--fw-bold);
`;

export const teamStyled = { StyledSection, Wrapper, TeamWrapper, Slogan };