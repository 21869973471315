import styled from 'styled-components';
import img from '../../img/srages-img.svg';

const StyledSection = styled.section`
  background: no-repeat right 20px top 20px url(${img}), var(--gradient);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
    "heading ."
    "stages description";
    gap: var(--gap) 20px;
  }
`;

const StagesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  counter-reset: stageId;
  grid-area: stages;
`;

const StagesItem = styled.span`
  color: white;
  position: relative;
  padding-left: 34px;

  ::before {
    counter-increment: stageId;
    content: counter(stageId);
    display: inline-block;
    width: 24px;
    height: 24px;
    color: var(--blackpearl);
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    background: var(--gradient-light);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @media (min-width: 640px) {
    font-size: 18px;
  }
`;

const Description = styled.p`
  color: white;
  grid-area: description;
`;

export const stagesStyled = { StyledSection, Wrapper, StagesList, StagesItem, Description };