import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 285px;
  min-height: 315px;
  padding: 30px 30px 15px;
  margin: 0 auto;

  background: var(--gradient);
  border-radius: 24px;
  color: white;

  display: flex;
  flex-direction: column;
  gap: 10px;

  :hover {
    background-image: var(--gradient-reverse);
  }

  @media (min-width: 640px) {
    min-width: 220px;
  }
`;

const Title = styled.div`
  min-height: 72px;
  height: 30%;
  display: flex;
  align-items: center;
`;

const SmallP = styled.p`
  font-size: 14px;
`;

export const serviceStyled = { Title, Wrapper, SmallP };