import styled from 'styled-components';
import quotes from '../../img/quotes.svg';

export const HeadingWrapper = styled.div`
  text-align: center;

  ::after {
    content: url(${quotes});
    text-align: center;
    display: block;
    margin: var(--gap) auto;
  }
`;