import { Container } from '../Container';
import { Heading } from '../Heading';
import { stages } from '../../content';
import { stagesStyled } from './Stages.styled';


export const Stages = () => {
  
  const { StyledSection, Wrapper, StagesList, StagesItem, Description } = stagesStyled;
  const { id, heading, items, description } = stages;
  
  return (
    <StyledSection id={id}>
      <Container>
        <Wrapper>
          <Heading {...heading} white />
          <StagesList>
            {
              items.map(item => <StagesItem key={item}>{item}</StagesItem>)
            }
          </StagesList>
          <Description>{description}</Description>
        </Wrapper>
      </Container>
    </StyledSection>
  );
};

