import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { textGradient } from '../mixins';

export const StyledSlider = styled(Slider)`
  margin-bottom: 50px;

  .slick-dots {
    bottom: -50px;
  }

  li button:before {
    font-size: 10px;
    color: var(--blackpearl);
  }

  li.slick-active button:before {
    ${textGradient};
  }
`;