import styled from 'styled-components';

export const TeamCardStyled = styled.div`
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 335px;
  padding: 30px 0;
`;
