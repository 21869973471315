import { useEffect, useRef, useState } from 'react';
import { SocialButtons } from '../SocialButtons';
import { menu, social } from '../../content';
import { burgerMenuStyled } from './BurgerMenu.styled';


export const BurgerMenu = ({ isOpen, close, hide }) => {
  
  const { Menu, MenuItem, SocialWrapper } = burgerMenuStyled;
  const [isOpening, setIsOpening] = useState(false);
  const ref = useRef();
  
  useEffect(() => {
    const listener = event => {
      if (ref.current.contains(event.target)) {
        return;
      }
      close();
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  });
  
  useEffect(() => {
    setIsOpening(isOpen);
  }, [isOpen]);
  
  useEffect(() => {
    const listener = event => {
      if (ref.current === event.target && !isOpen) hide();
    }
    document.addEventListener('transitionend', listener);
    return () => {
      document.removeEventListener('transitionend', listener);
    };
  });
  
  
  return (
    <Menu ref={ref} isOpen={isOpening}>
      {
        menu.map((item) =>
          <MenuItem key={item} href={'#' + item.toLowerCase().replace(' ', '-')} onClick={close}>
            {item}
          </MenuItem>
        )
      }
      <SocialWrapper>
        <SocialButtons links={social} />
      </SocialWrapper>
    </Menu>
  )
}