import { serviceStyled } from './Service.styled';


export const Service = ({ title, text }) => {
  
  const { Title, Wrapper, SmallP } = serviceStyled;
  
  return (
    <Wrapper>
      <Title>
        <h3>{title}</h3>
      </Title>
      <SmallP>{text}</SmallP>
    </Wrapper>
  );
};

