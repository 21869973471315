import { Container } from '../Container';
import { SocialButtons } from '../SocialButtons';
import { MainMenu } from '../MainMenu';
import { contacts, social } from '../../content';
import { footerStyled } from './Footer.styled';
import logo from '../../img/logo.svg';


export const Footer = () => {
  
  const { ContactsWrapper, ContactsList, MenuWrapper, Wrapper } = footerStyled;
  const { id, items } = contacts;
  
  return (
    <footer id={id}>
      <Container>
        <Wrapper>
          <ContactsWrapper>
            <img src={logo} alt="IT-ML" />
            <ContactsList>
              {
                items.map(item => <p key={item.value}>{item.name}: <a href={item.href}>{item.value}</a></p>)
              }
            </ContactsList>
            <SocialButtons links={social} />
          </ContactsWrapper>
          <MenuWrapper>
            <MainMenu />
          </MenuWrapper>
        </Wrapper>
      </Container>
    </footer>
  );
};

