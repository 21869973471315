import { useState } from 'react';
import { SocialButtons } from '../SocialButtons';
import { MainMenu } from '../MainMenu';
import { BurgerBtn } from './BurgerBtn';
import { BurgerMenu } from './BurgerMenu';
import { useMatchMedia } from '../../hooks';
import { social } from '../../content';
import { styledHeader } from './Header.styled';
import logo from '../../img/logo.svg';


export const Header = () => {
  
  const { Logo, MenuWrapper, StyledHeader } = styledHeader;
  const { isMobile } = useMatchMedia();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
  const toggleBurger = () => {
    if (isVisible === false) setIsVisible(true);
    setIsOpen(!isOpen);
  }
  
  return (
    <StyledHeader>
      <Logo src={logo} alt="IT-ML" />
      {
        isMobile
          ?
          <>
            <BurgerBtn isOpen={isOpen} toggle={toggleBurger} />
            {
              isVisible &&
              <BurgerMenu isOpen={isOpen} close={() => setIsOpen(false)} hide={() => setIsVisible(false)} />
            }
          </>
          :
          <>
            <MenuWrapper>
              <MainMenu />
            </MenuWrapper>
            <SocialButtons links={social} />
          </>
      }
    </StyledHeader>
  );
};

