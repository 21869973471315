import { Heading } from '../Heading';
import { Container } from '../Container';
import { features } from '../../content';
import { featuresStyled } from './Features.styled';
import img from '../../img/features.png';


export const Features = () => {
  
  const { Wrapper, TextWrapper, Feature, Image, StyledSection } = featuresStyled;
  const { id, heading, items } = features;
  
  return (
    <StyledSection id={id}>
      <Container>
        <Wrapper>
          <Image src={img} alt="Features" />
          <TextWrapper>
            <Heading {...heading} />
            {
              items.map(item =>
                <Feature key={item.title}>
                  <Heading {...item} tag="h3" />
                </Feature>)
            }
          </TextWrapper>
        </Wrapper>
      </Container>
    </StyledSection>
  );
};

