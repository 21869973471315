import styled from 'styled-components';
import bg from '../../img/background-vacancies.svg';

const StyledSection = styled.section`
  background: var(--gradient-light);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
`;

const HeadingBg = styled.div`
  background-image: url(${bg});
  background-size: cover;
`;

const VacancyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const vacanciesStyled = { StyledSection, Wrapper, HeadingBg, VacancyWrapper };