import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  height: 50px;
  color: white;
  background: var(--gradient);
  border: 1px solid white;
  border-radius: 8px;
  font-family: var(--family);
  font-size: 18px;

  :hover {
    background: var(--gradient-reverse);
    cursor: pointer;
  }
`;

const BtnWhite = styled(Button)`
  width: 167px;
  max-width: 50%;
  border-radius: 0 8px 8px 0;
  color: var(--blackpearl);
  background: white;

  :hover {
    background: var(--gradient-white);
  }
`;

const BtnBlue = styled(Button)`
  width: 167px;
  max-width: 50%;
  border-radius: 8px 0 0 8px;
`;

export { Button, BtnWhite, BtnBlue };