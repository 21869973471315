import { createGlobalStyle } from 'styled-components';

//@import doesn't work in the current version (v. 5.3.5)
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
//@import-normalize;

export const GlobalStyles = createGlobalStyle`

  * {
    box-sizing: border-box;
    margin: 0;
  }

  :root {
    --family: 'Poppins', sans-serif;
    --fw-normal: 400;
    --fw-bold: 700;
    --gradient: linear-gradient(180deg, #0281FD 0%, #05C5DF 100%);
    --gradient-reverse: linear-gradient(0deg, #0281FD 0%, #05C5DF 100%);
    --gradient-light: linear-gradient(180deg, #D9EEFF 0%, #CFF8FF 100%);
    --gradient-white: linear-gradient(180deg, white 0%, #FFFFFF00 100%);
    --blackpearl: #072125;
    --snow: #FAFAFA;
    --gap: 40px;

    @media (min-width: 640px) {
      --gap: 50px;
    }
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--family);
    font-weight: var(--fw-normal);
    font-size: 1rem;
    line-height: 1.2;
    color: var(--blackpearl);
    
    @media (min-width: 640px) {
      line-height: 1.5;
    }
  }
  
  h1 {
    font-size: 4rem;
    line-height: 1.2;
  }
  
  h2, .h2-style {
    font-size: 2rem;
    
    @media (min-width: 640px) {
      font-size: 2.5rem;
    }
  }
  
  h3, .h3-style, .h3-size {
    font-size: 1.5rem;
  }
  
  .h2-style, .h3-style {
    font-weight: var(--fw-bold);
  }
  
  p {
    white-space: pre-line;
  }

  section, footer {
    padding: 60px 0;
  }
`;