import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0 20px;
  
  @media (min-width: 640px) {
    width: 1200px;
    max-width: 95%;
    margin: 0 auto;
    padding: 0;
  }
`;

