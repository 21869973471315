import { Container } from '../Container';
import { Heading } from '../Heading';
import { trustStyled } from './Trust.styled';
import { trust } from '../../content';


export const Trust = () => {
  
  const { Wrapper, TextWrapper, TrustItem } = trustStyled;
  const { heading, items } = trust;
  
  return (
    <section>
      <Container>
        <Wrapper>
          <Heading {...heading} />
          <TextWrapper>
            {
              items.map(item => <TrustItem key={item}>{item}</TrustItem>)
            }
          </TextWrapper>
        </Wrapper>
      </Container>
    </section>
  );
};

