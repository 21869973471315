import { Container } from '../Container';
import { Heading } from '../Heading';
import { TeamCard } from './TeamCard';
import { teamStyled } from './Team.styled';
import { team } from '../../content';


export const Team = () => {
  
  const { StyledSection, Wrapper, TeamWrapper, Slogan } = teamStyled;
  const { id, heading, slogan, items } = team;
  
  return (
    <StyledSection id={id}>
      <Container>
        <Wrapper>
          <div>
            <Heading {...heading} />
            <Slogan>{slogan}</Slogan>
          </div>
          <TeamWrapper>
            {
              items.map(item => <TeamCard key={item.name} {...item} />)
            }
          </TeamWrapper>
        </Wrapper>
      </Container>
    </StyledSection>
  );
};

