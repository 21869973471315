import { SocialButtons } from '../SocialButtons';
import { TeamCardStyled } from './TeamCard.styled';


export const TeamCard = ({ name, position, photo, social }) => {
  
  return (
    <TeamCardStyled>
      {/*<img width="220px" src={'team/' + photo} alt={name} />*/}
      <div>
        <p className='h3-style'>{name}</p>
        <span>{position}</span>
      </div>
      <SocialButtons links={social} />
    </TeamCardStyled>
  );
};

