import { topSection } from '../../content';
import { BtnBlue, BtnWhite } from './SwitchButtons.styled';

export const SwitchButtons = ({ handleClick }) => {
  
  const { cardTypes } = topSection;
  
  return (
    <div onClick={handleClick}>
      <BtnBlue>{cardTypes[0]}</BtnBlue>
      <BtnWhite>{cardTypes[1]}</BtnWhite>
    </div>
  );
};

