import styled from 'styled-components';
import { textGradient } from '../mixins';

const Title = styled.h2.attrs(({ tag }) => tag && { as: tag })`
  white-space: pre-line;
  margin-bottom: ${props => props.tag === 'h1' ? '30px' : '10px'};
  grid-area: heading;
  ${props => props.white ? 'color: white;' : textGradient};
`;

const Description = styled.p`
  ${props => props.tag === 'h1' && 'font-size: 18px'};
  ${props => props.white && 'color: white'};
`;


export const Heading = ({ title, description = '', tag = false, white = false }) => {
  return (
    <div>
      <Title tag={tag} white={white}>{title}</Title>
      {description && <Description tag={tag} white={white}>{description}</Description>}
    </div>
  );
};

