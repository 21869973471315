import { useState } from 'react';
import { Container } from '../Container';
import { Header } from './Header';
import { SwitchButtons } from './SwitchButtons';
import { FeedbackCard } from './FeedbackCard';
import { Heading } from '../Heading';
import { useMatchMedia } from '../../hooks';
import { topSectionStyled } from './TopSection.styled';
import { topSection } from '../../content';


export const TopSection = () => {
  
  const { StyledSection, TitleWrapper, Wrapper } = topSectionStyled;
  const { id, heading, cardTypes } = topSection;
  
  const [title, setTitle] = useState(cardTypes[0]);
  const { isMobile } = useMatchMedia();
  
  const handleClick = event => {
    event.preventDefault();
    setTitle(event.target.textContent);
  };
  
  return (
    <StyledSection id={id}>
      <Header />
      <Container>
        <Wrapper>
          <TitleWrapper>
            <Heading {...heading} tag={!isMobile && 'h1'} white />
            <SwitchButtons handleClick={handleClick} />
          </TitleWrapper>
          <FeedbackCard title={title} />
        </Wrapper>
      </Container>
    </StyledSection>
  );
};

