import { Heading } from '../Heading';
import { Vacancy } from './Vacancy';
import { Container } from '../Container';
import { StyledSlider } from '../StyledSlider';
import { useMatchMedia } from '../../hooks';
import { vacanciesStyled } from './Vacancies.styled';
import { vacancies } from '../../content';
import { useContentData } from '../../hooks';


export const Vacancies = () => {
  
  const { StyledSection, HeadingBg, VacancyWrapper, Wrapper } = vacanciesStyled;
  const { isMobile } = useMatchMedia();
  const items = useContentData('vacancies');
  const { id, heading } = vacancies;
  
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  
  const children = items.map((item, idx) => <Vacancy key={idx} data={item} />)
  
  return (
    <StyledSection id={id}>
      <Wrapper>
        <HeadingBg>
          <Container>
            <Heading {...heading} />
          </Container>
        </HeadingBg>
        <Container>
          {
            isMobile
              ?
              <VacancyWrapper children={children} />
              :
              <StyledSlider {...settings} children={children} />
          }
        </Container>
      </Wrapper>
    </StyledSection>
  );
};

