import { styledReviewer } from './Review.styled';


export const Review = ({ item }) => {
  
  const { Photo, Name, Reviewer, Wrapper } = styledReviewer;
  
  return (
    <Wrapper key={item.id}>
      <p>{item.text}</p>
      <Reviewer photo={item.photo}>
        <Photo src={'reviews/' + item.photo} alt="photo" />
        <Name>{item.name}</Name>
        <span>{item.position}, {item.company}</span>
      </Reviewer>
    </Wrapper>
  );
};

