import styled from 'styled-components';

const Text = styled.p`
  padding: 1rem 0;
  font-size: 14px;
  text-align: center;
  border-top: 1px solid rgba(7, 33, 37, 0.1);
`;

export const Copyright = () => {
  
  const currentYear = new Date(Date.now()).getFullYear();
  
  return (
    <Text>
      Copyright 2010-{currentYear} IT-ML LTD
    </Text>
  );
};

