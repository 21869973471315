import styled from 'styled-components';
import { textGradient } from '../../mixins';

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  max-width: 375px;
  padding-top: 75px;
  border-radius: 0 0 20px 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-in-out;
  z-index: 5;
`;

const MenuItem = styled.a`
  font-size: 1.5rem;
  text-decoration: none;
  color: var(--blackpearl);
  padding: 15px 20px;
  border-top: 1px solid rgba(7, 33, 37, 0.1);

  &:hover {
    ${textGradient};
  }
`;

const SocialWrapper = styled.div`
  padding: 15px 20px;
  border-top: 1px solid rgba(7, 33, 37, 0.1);
`;

export const burgerMenuStyled = { MenuItem, SocialWrapper, Menu };