import { Container } from '../Container';
import { Heading } from '../Heading';
import { Service } from './Service';
import { StyledSlider } from '../StyledSlider';
import { services } from '../../content';
import { useMatchMedia } from '../../hooks';
import { servicesStyled } from './Services.styled';


export const Services = () => {
  
  const { Wrapper, ServicesWrapper, Brands } = servicesStyled;
  const { id, heading, items, brands } = services;
  
  const { isMobile } = useMatchMedia();
  
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  const children = items.map(({ title, text }) => <Service key={title} title={title} text={text} />);
  
  return (
    <section id={id}>
      <Container>
        <Wrapper>
          <Heading {...heading} />
          {
            isMobile
              ?
              <StyledSlider {...settings} children={children} />
              :
              <ServicesWrapper children={children} />
          }
          <Brands>
            {
              brands.map(svg => <img
                key={svg}
                width="100%"
                src={`brands/${svg}.svg`}
                alt={'brand_' + svg}
              />)
            }
          </Brands>
        </Wrapper>
      </Container>
    </section>
  );
};

