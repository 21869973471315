import styled from 'styled-components';
import img from '../../img/background.svg';

const StyledSection = styled.section`
  background-image: url(${img}), var(--gradient);
  background-size: cover;
  border-radius: 0 0 20px 20px;
  padding-top: 0;
`;

const Wrapper = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap);

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: var(--gap);

  @media (min-width: 640px) {
    text-align: left;
    width: 50%;
  }
`;

export const topSectionStyled = { StyledSection, Wrapper, TitleWrapper };