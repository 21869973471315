import { Container } from '../Container';
import { StyledSlider } from '../StyledSlider';
import { Review } from './Review';
import { reviews } from '../../content';
import { useMatchMedia, useContentData } from '../../hooks';
import { HeadingWrapper } from './Reviews.styled';
import { Heading } from '../Heading';


export const Reviews = () => {
  
  const { isMobile } = useMatchMedia();
  const items = useContentData('reviews');
  const { id, heading } = reviews;
  
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: isMobile ? 1 : 2
  };
  
  return (
    <section id={id}>
      <Container>
        <HeadingWrapper>
          <Heading {...heading}></Heading>
        </HeadingWrapper>
          <StyledSlider {...settings}>
            {
              items.map(item => <Review key={item.id} item={item} />)
            }
          </StyledSlider>
      </Container>
    </section>
  )
    ;
};

