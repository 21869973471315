//main menu
export const menu = ['About', 'Services', 'Vacancies', 'Hiring Process', 'Clients', /*'Team', */'Contacts'];

//social corporate
export const social = {
  facebook: 'https://www.facebook.com/IT-ML-Recruiting-Agency-110001388455665',
  linkedin: 'https://www.linkedin.com/company/it-ml-recruiting-agency',
  twitter: '',
  instagram: 'https://www.instagram.com/itml.recruiting.agency'
};

//contacts
export const contacts = {
  id: 'contacts',
  items: [
    { name: 'email', value: 'hr@it-ml.agency', href: `mailto:hr@it-ml.agency` },
    { name: 'phone', value: '+49 1515 4740973', href: `tel:+4915154740973` },
    { name: 'location', value: 'Germany, Heidelberg' },
    { name: 'phone', value: '+1 403 8911382', href: `tel:+14038911382` },
    { name: 'location', value: 'Canada, Calgary' }
  ]
};

//Top section
export const topSection = {
  id: 'main',
  heading: {
    title: 'IT-ML Recruiting Agency',
    description: 'Providing complex solutions in IT recruiting'
  },
  fields: [
    {
      name: 'sender_name',
      label: 'Name (required)',
      placeholder: 'Your name',
      options: {
        required: 'This field is required',
        minLength: {
          value: 2,
          message: 'Too short'
        },
        pattern: {
          value: /^\s*[а-яА-ЯёЁіІїЇa-zA-Z]{2,20}\s*[а-яА-ЯёЁіІїЇa-zA-Z]{0,20}\s*$/,
          message: 'Incorrect form (1-2 words)'
        }
      }
    },
    {
      name: 'sender_phone',
      label: 'Phone (required)',
      type: 'tel',
      placeholder: 'Your phone number',
      options: {
        required: 'This field is required',
        minLength: {
          value: 10,
          message: 'Too short'
        },
        pattern: {
          value: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
          message: 'Incorrect form'
        }
      }
    },
    {
      name: 'sender_email',
      label: 'Email (required)',
      type: 'email',
      placeholder: 'Your contact email',
      options: {
        required: 'This field is required',
        pattern: {
          value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
          message: 'Incorrect form'
        }
      }
    },
    {
      name: 'message',
      label: 'Comment',
      placeholder: 'Some additional we should know about your request',
      tag: 'textarea',
      options: {}
    }
  ],
  uploadInput: {
    id: 'upload',
    type: 'file',
    name: 'sender_file',
    accept: 'application/pdf'
  },
  cardTypes: ['Find Staff', 'Find Job']
};

//Features section
export const features = {
  id: 'about',
  heading: {
    title: 'Who we are',
    description: 'We are a team of professionals engaged in IT recruiting for over 10 years. We successfully implement the best HR solutions and help international companies to find, interview, recruit, relocate and support adaptation of IT specialists.'
  },
  items: [
    {
      title: 'Professional Engagement',
      description: 'We create an atmosphere of trust and open communication. We use personal approach to each of our clients and applicants.'
    },
    {
      title: 'Flexibility',
      description: 'We value both the process and the result, we are open to various options of cooperation.'
    },
    {
      title: 'Results that matter',
      description: 'We responsibly move towards the intended goal, within the time frame set by the client.'
    }
  ]
};

//Services section
export const services = {
  id: 'services',
  heading: {
    title: 'Our Services',
    description: 'Selection of IT specialists of various qualifications and professional areas in the shortest possible time and on your terms'
  },
  items: [
    {
      title: 'Exclusive recruitment',
      text: 'Search and select IT talents of any function and different skill levels. We provide a free replacement of the candidate if the person has not passed the trial period in your company.'
    },
    {
      title: 'Drafting a vacancy',
      text: 'We will help you to create the most attractive vacancy for the candidate, taking into consideration all the details that are important for you as an employer, as well as meeting the latest IT market trends.'
    },
    {
      title: 'Professional assessment ',
      text: 'We evaluate the candidate according to key skills and competencies, the level of proficiency in the specified technology, soft and hard skills.'
    },
    {
      title: 'Market research and analytics',
      text: 'We analyze the job seeker market, offers on the market for the vacancy you specified, provide a summary of the salary gap according to the characteristics of the ideal candidate you have provided.'
    },
    {
      title: 'Sourcing',
      text: 'In the search process, we can include sites such as GitHub, Gitter, Behance, LinkedIn, and we also search for candidates at various forums, conferences, exhibitions and other events.'
    },
    {
      title: 'Executive search and headhunting',
      text: 'We search and attract of talents of rare qualifications, as well as top-level managers. We start the process with a direct targeted search, market research, salary research and up to the correct process of poaching a specialist.'
    },
    {
      title: 'HR consulting',
      text: 'We provide a range of services related to improving the efficiency of personnel, help optimize relationships between employees, as well as build internal HR processes in the company.'
    },
    {
      title: 'Relocation',
      text: 'We support the candidate in relocation, assistance with documents, adaptation to a new place of work and residence, trip supervision.'
    }
  ],
  //filenames in public/brands
  brands: ['apache', 'vagrant', 'ceylon', 'github', 'cakephp', 'couchdb', 'django', 'ionic', 'java', 'mysql', 'nginx', 'nodejs', 'nodejs2', 'pycharm', 'sketch', 'sequelize', 'swift', 'devicon', 'webpack', 'yarn']
};

//Vacancies section
export const vacancies = {
  id: 'vacancies',
  heading: {
    title: 'Vacancies',
    description: 'The best vacancies for the best people'
  }
};

//Trust section
export const trust = {
  heading: {
    title: 'Why companies trust us',
    description: 'The new way to work with recruitment'
  },
  items: [
    'Highly qualified recruiters',
    'Guaranteed selection of best IT talents',
    'Free consultations on the labor market',
    'More than 1000 specialists in the own database',
    'Saving your personal time',
    'Respect ethics and confidentiality'
  ]
};

//Stages section
export const stages = {
  id: 'hiring-process',
  heading: {
    title: 'Stages of working with a client',
    description: 'If you have already decided to use our professional services, we are happy to share with you a plan of our actions on the way to the successful filling of a vacancy for your company'
  },
  items: [
    'Identification of needs',
    'Signing the contract',
    'Preparation of the recruitment plan',
    'Search for candidates',
    'Interviewing candidates',
    'Providing resumes of relevant candidates with recruiter comments',
    'Preparation of statistics, analytics on the labor market',
    'Assistance in negotiations and drafting a Job Offer to a candidate',
    'Accompanying the candidate during the trial period'
  ],
  description:
    'In standard cases, the cost of our services is equivalent to one salary of the candidate. We will also provide free replacement within 90 days if the provided candidate has not passed trial period in your company.\n\nThe final amount and procedure for paying remuneration are fixed with the Customer at the conclusion of the Contract for the selection of the relevant candidate. In standard cases work is done without prepayment.\n\nThe final cost of services is negotiated with each client individually.'
};

//Reviews section
export const reviews = {
  id: 'clients',
  heading: {
    title: 'Clients about IT-ML Recruiting Agency'
  }
};

//Team section
export const team = {
  id: 'team',
  heading: {
    title: 'A team of exceptional people'
  },
  slogan: 'WE DISCOVER THE BEST AND VALUE EVERYONE',
  items: [
    {
      name: 'Marina Weber',
      position: 'Co-founder',
      photo: 'MarinaBozhagora.png',
      social: {
        facebook: '',
        linkedin: 'https://www.linkedin.com/in/marina-weber-b1230a2b0',
        twitter: '',
        instagram: ''
      }
    },
    {
      name: 'Lucy Roy',
      position: 'Co-founder',
      photo: '',
      social: {
        facebook: '',
        linkedin: 'https://www.linkedin.com/in/lucy-roy-aa1b762ab',
        twitter: '',
        instagram: ''
      }
    }
  ]
};