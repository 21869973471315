import styled from 'styled-components';
import { menu } from '../content'
import { textGradient } from '../mixins';

const MenuItem = styled.a`
  color: var(--blackpearl);
  text-decoration: none;
  white-space: nowrap;

  :hover {
    cursor: pointer;
    ${textGradient};
  }
`;

export const MainMenu = () => {
  
  return (
    <>
      {
        menu.map(item =>
          <MenuItem key={item} href={'#' + item.toLowerCase().replace(' ', '-')}>
            {item}
          </MenuItem>
        )
      }
    </>
  );
};

