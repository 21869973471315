import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  @media (min-width: 640px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`;

const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media (min-width: 640px) {
    width: 320px;
    gap: 30px;
  }
`;

const ContactsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuWrapper = styled.nav`
  display: grid;
  grid: auto-flow / repeat(2, min-content);
  gap: 10px 30px;
  align-items: center;

  @media (min-width: 640px) {
    grid: auto-flow / repeat(3, auto);
    gap: 20px 100px;
  }
`;

export const footerStyled = { Wrapper, ContactsWrapper, ContactsList, MenuWrapper };