// Current emailjs plan doesn't support file attachment. Just uncomment below when possible.

// import { useState } from 'react';
// import { UploadInput } from './UploadInput';
import { useForm } from 'react-hook-form';
import * as emailjs from '@emailjs/browser';
import { styledFeedback } from './FeedbackCard.styled';
import { topSection } from '../../content';
import { Button } from './SwitchButtons.styled';
import Swal from 'sweetalert2';

export const FeedbackCard = ({ title }) => {
  
  const { Card, Form, Input, ErrorMessage, ErrorField } = styledFeedback;
  const { fields, cardTypes } = topSection;
  
  // const [fileName, setFileName] = useState(null);
  
  const {
          register,
          formState: { errors, isValid },
          handleSubmit,
          reset
        } = useForm({
    mode: 'onChange'
  });
  
  const submitHandler = (data, event) => {
    event.preventDefault();
    
    emailjs.sendForm(
      'service_nic.smtp',
      'template_' + (title === cardTypes[0] ? 'client' : 'candidate'),
      event.target,
      process.env.REACT_APP_PUBLIC_KEY
    ).then((result) => {
      console.log(result.text);
      reset();
      // setFileName(null);
      
      Swal.fire({
        title: 'Form sent!',
        text: 'Please wait, we will contact you soon',
        icon: 'success',
        confirmButtonColor: '#0281FD',
      });
    }, (error) => {
      console.log(error.text);
    });
  }
  
  return (
    <Card white={title === cardTypes[0]}>
      <p className='h2-style'>{title}</p>
      <Form onSubmit={handleSubmit(submitHandler)}>
        
        {
          fields.map(({
                        name,
                        label,
                        type = 'text',
                        placeholder = '',
                        tag = 'input',
                        options = {}
                      }) => (
            <label key={name}>
              {label}
              <Input
                as={tag}
                type={type}
                placeholder={placeholder}
                {...register(name, options)}
              />
              <ErrorField>{errors?.[name] &&
                <ErrorMessage>{errors?.[name]?.message || 'Error!'}</ErrorMessage>}</ErrorField>
            </label>
          ))
        }
        
        {/*<UploadInput fileName={fileName} chooseFile={setFileName} />*/}
        
        <Button type="submit" disabled={!isValid}>{title}</Button>
      
      </Form>
    </Card>
  );
};

